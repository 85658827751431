<template>
  <div style="margin-top: 10px;margin-left: 10px">
    <el-row>
      <el-button type="primary" @click="addState = true" style="margin-bottom: 10px">添加婚纱尺码</el-button>
    </el-row>
    <el-row>
      <el-table
          :data="sizeList"
          border
          max-height="700"
          :row-style="{height: '38px'}"
          :cell-style="{padding: '0'}"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '9px 0'}"
          :default-sort="{prop: 'id', order: 'descending'}"
          style="width: 99%">
        <el-table-column label="尺码名称" align="center" #default="scope">
          {{scope.row.sizeName}}
          <el-badge type="primary" value="系统默认" v-if="scope.row.isDefault === 1"/>
        </el-table-column>
        <el-table-column label="是否激活" align="center">
          <template v-slot="scope">
            <el-switch
                @change="isValidChange(scope.row)"
                v-model="scope.row.isValid"
                :active-value="1"
                :inactive-value="0"
                :disabled="scope.row.isDefault === 1"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="是否系统默认参数" v-if="isSuperAdmin" align="center" #default="scope">
          <el-switch
              @change="isChange(scope.row)"
              v-model="scope.row.isDefault"
              :active-value="1"
              :inactive-value="0"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </el-table-column>
        <el-table-column align="center" fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button-group >
              <el-button type="success" size="mini" v-if="scope.row.isDefault!==1" @click="openEdit(scope.row)">编辑</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="limit"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <el-dialog
        title="添加婚纱尺码"
        width="25%"
        :visible.sync="addState"
        :modal-append-to-body="false"
        @close="addState = false">
      <el-form label-width="100px" :model="addFrom" :rules="rules" ref="addForm">
        <el-row>
          <el-col :span="22">
            <el-form-item label="尺码名称" prop="sizeName">
              <el-input v-model="addFrom.sizeName" size="medium" placeholder="请填写婚纱尺码名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addState = false" size="medium">取 消</el-button>
        <el-button type="primary" @click="clothesSizeAdd" size="medium">提 交</el-button>
      </span>
    </el-dialog>
    <el-dialog
        title="修改婚纱尺码"
        width="25%"
        :visible.sync="editState"
        :modal-append-to-body="false"
        @close="editState = false">
      <el-form label-width="100px" :model="editForm" :rules="rules" ref="editForm">
        <el-row>
          <el-col :span="22">
            <el-form-item label="尺码名称" prop="sizeName">
              <el-input v-model="editForm.sizeName" size="medium" placeholder="请填写婚纱尺码名称"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editState = false" size="medium">取 消</el-button>
        <el-button type="primary" @click="clothesSizeEdit" size="medium">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "clothes-size-list",
  created() {
    this.pageInit()
  },
  data() {
    return {
      sizeList: [],
      page: 1,
      limit: 100,
      total: 0,
      loading: false,
      addState: false,
      editState: false,
      addFrom: {
        sizeName: "",
      },
      rules: {
        sizeName: [{required: true, message: '请填写婚纱尺寸名称', trigger: 'blur'}],
      },
      editForm: {
        id: "",
        sizeName: ""
      },
      isSuperAdmin: localStorage.getItem("tenantCrop") === '2a31c23a-c178-441614928053489'
    }
  },
  methods: {
    pageInit() {
      this.querySizeList()
    },
    querySizeList() {
      this.$axios({
        method: "get",
        url: "/clothes/clothesSizeList",
        params: {
          page: this.page,
          limit: this.limit,
          tenantCrop: localStorage.getItem("tenantCrop")
        }
      }).then(response => {
        this.total = response.data.data.total
        this.sizeList = response.data.data.list
      })
    },
    handleSizeChange(val) {
      this.limit = val;
      this.querySizeList();
    },
    handleCurrentChange(val) {
      this.page = val
      this.querySizeList()
    },
    openEdit(val) {
      this.editState = true
      this.editForm.sizeName = val.sizeName
      this.editForm.id = val.id
    },
    isValidChange(val) {
      this.$axios({
        method: "POST",
        url: "/clothes/updateClothesSize",
        params: {
          id: val.id,
          isValid: val.isValid
        }
      }).then(response => {
        let isValidState = response.data.code === 200;
        this.$message({
          message: isValidState ? "修改激活状态成功" : response.data.msg,
          type: isValidState ? "success" : 'error'
        });
      })
    },
    clothesSizeAdd() {
      this.$refs['addForm'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "POST",
            url: "/clothes/clothesSizeAdd",
            params: {
              sizeName: this.addFrom.sizeName,
              tenantCrop: localStorage.getItem("tenantCrop"),
              isValid: 1,
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '添加成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            if (flag) {
              this.addState = false
              this.querySizeList()
              this.addFrom.sizeName = ""
            }
          })
        }
      })
    },
    clothesSizeEdit() {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          this.$axios({
            method: "post",
            url: "/clothes/updateClothesSize",
            params: {
              ...this.editForm
            }
          }).then(response => {
            let flag = response.data.code === 200
            this.$message({
              showClose: true,
              message: flag ? '修改成功': response.data.msg,
              type: flag ? 'success':'error',
              duration: 1000,
            });
            if (flag) {
              this.editState = false
              this.querySizeList()
            }
          })
        }
      })
    },
    isChange(val) {
      this.$axios({
        method: 'post',
        url: "/clothes/updateClothesSize",
        params: {
          id: val.id,
          isDefault: val.isDefault
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '修改成功': response.data.msg,
          type: flag ? 'success':'error',
          duration: 1000,
        });
        this.querySizeList()
      })
    }
  }
}
</script>

<style scoped>
</style>